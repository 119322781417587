import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'

import { ButtonLink } from '../components/Buttons'

const ContactWrapper = styled.div`
    padding: 2.5rem 0;

    ${({ theme }) => theme.media.md`
        padding: 7.5rem 0;
    `}

    img {
        width: 27.5rem;
    }

    h3 {
        transform: translate(-5.5rem, 0);
    }
`;

const StyledList = styled.dl.attrs({
  className: 'row mt-3 mb-4',
})`
  dt, dd {
    font-weight: normal;
    font-style: italic;
  }
`

const AddressWrapper = styled.div.attrs({
  className: 'mb-5',
})`
  p {
    margin-bottom: 0;
  }
`

const Contact = () => {
    const data = useStaticQuery(graphql`
    {
      prismic {
        allContacts {
          edges {
            node {
              address
              content
              email
              instagram_link {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              instagram_name
              facebook_link {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              facebook_name
              map
              phone
              photo
              title
            }
          }
        }
      }
    }
  `)

  const { title, content, photo, address, email, phone, map, instagram_name, instagram_link, facebook_name, facebook_link } = data.prismic.allContacts.edges[0].node

    return (
        <ContactWrapper>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 mb-5 mb-md-0 text-center">
                        <img src={photo.url} alt={photo.alt} />
                    </div>
                    <div className="col-md-5 offset-md-1 d-flex flex-column justify-content-center">
                        <h2 className="subtitle mb-4">{RichText.asText(title)}</h2>
                        <RichText render={content} />
                        <StyledList>
                          <dt className="col-sm-2">tel.:</dt>
                            <dd className="col-sm-10">{phone}</dd>
                          <dt className="col-sm-2">email.:</dt>
                            <dd className="col-sm-10">
                              <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
                                {email}
                              </a>
                            </dd>
                          <dt className="col-sm-2">Ig.:</dt>
                            <dd className="col-sm-10">
                              <a href={instagram_link.url} target="_blank" rel="noopener noreferrer">
                                {instagram_name}
                              </a>
                            </dd>
                          <dt className="col-sm-2">Fb.:</dt>
                            <dd className="col-sm-10">
                              <a href={facebook_link.url} target="_blank" rel="noopener noreferrer">
                                {facebook_name}
                              </a>
                            </dd>
                        </StyledList>
                        <h2 className="subtitle mb-4">Sídlo všudekytky</h2>
                        <AddressWrapper>
                          <RichText render={address} />
                        </AddressWrapper>
                        <ButtonLink external to={`https://www.google.com/maps/place/${map.latitude},${map.longitude}`} >
                          + zobrazit na mapě
                        </ButtonLink>
                    </div>
                </div>
            </div>
        </ContactWrapper>
    )
}

export default Contact

import React from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'
import { Link } from "gatsby"

const GatsbyButtonLink = styled(Link).attrs({
    className: 'btn btn-primary py-4 px-5 subtitle',
    role: 'button',
})`
    background-color: ${props => props.theme.colors.button};
    border: none;
    border-radius: 0;

    &:hover,
    &&&:active,
    &:focus {
        background-color: ${props => props.theme.colors.hover};
    }
`

const RegularButtonLink = styled.a.attrs({
    className: 'btn btn-primary py-4 px-5 subtitle',
    role: 'button',
    target: '_blank',
    rel: 'noopener noreferrer'
})`
    background-color: ${props => props.theme.colors.button};
    border: none;
    border-radius: 0;

    &:hover,
    &&&:active,
    &:focus {
        background-color: ${props => props.theme.colors.hover};
    }
`

const ButtonLink = ({ children, external, to, ...props }) => external
    ? <RegularButtonLink href={to} {...props}>{children}</RegularButtonLink>
    : <GatsbyButtonLink to={to} {...props}>{children}</GatsbyButtonLink>

ButtonLink.defaultProps = {
    to: '#',
    external: false,
}

ButtonLink.propTypes = {
    to: PropTypes.string,
    external: PropTypes.bool,
    children: PropTypes.node.isRequired,
}

export {
    ButtonLink
}

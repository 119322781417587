import React from "react"

import SEO from '../components/seo'
import Layout from '../components/layout';
import Contact from '../components/Contact'

const ContactPage = () => (
    <Layout subpage>
        <SEO title="Kontakt" />
        <Contact />
    </Layout>
)

export default ContactPage
